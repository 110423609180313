// @ts-strict-ignore
import React, { useContext, useEffect, useRef, useState } from "react"

import { addDays, startOfDay } from "date-fns"
import { utcToZonedTime, zonedTimeToUtc } from "date-fns-tz"
import { Form, Formik, useField, useFormikContext } from "formik"
import { Helmet } from "react-helmet"
import styled, { ThemeContext } from "styled-components"

import {
  Alert,
  Box,
  Button,
  Checkbox,
  Heading,
  Loading,
  Popover,
  Stack,
  Text,
  TextLink,
  Tooltip,
} from "@kiwicom/orbit-components"
import {
  Alert as AlertIcon,
  Attachment as AttachmentIcon,
  Calendar as CalendarIcon,
  CheckCircle as CheckCircleIcon,
  Circle as CircleIcon,
  Placeholder as PlaceholderIcon,
} from "@kiwicom/orbit-components/icons"

import {
  ChecklistModificationPayload,
  ValidationMessage,
  fetchChecklistByUid,
  markChecklistAsComplete,
  modifyChecklist,
  updateChecklistItem,
} from "api/checklists"
import { EmberApiError } from "api/errors"

import { ConversationView } from "components/chat"
import { EmberInlineDatePicker } from "components/generic/date-time/ember-inline-date-picker"
import {
  EmberCard,
  EmberCardGroup,
  EmberCardSection,
} from "components/generic/ember-card"
import { useAttachments } from "components/generic/file-upload"
import {
  EmberDateInput,
  EmberNumberInput,
  EmberRadioGroup,
  EmberTextArea,
} from "components/generic/formik-inputs"
import { ShadowOnHover } from "components/generic/shadow-on-hover"

import { Activity } from "types/activity"
import { Checklist, ChecklistItem, checklistTypeToLabel } from "types/checklist"
import { Unsubscribable } from "types/observable"
import { UploadedFile } from "types/uploaded-file"
import { VehicleSummary } from "types/vehicle"

import {
  formatAsAbbreviatedDate,
  formatAsDate,
  formatAsDateTime,
  getDefaultTimezone,
} from "utils/date-utils"
import { getPersonName } from "utils/name-utils"
import { useGlobalFetcher } from "utils/state-utils"
import { sortBy } from "utils/struct-utils"

import { IconAndText } from "../components/badges"
import { WithInlineSpinnerAndAlerts } from "../components/inline-spinner-and-alerts"
import {
  ScheduledWorkModal,
  describeScheduledWork,
} from "../components/scheduled-work-modal"
import { VehicleSelector } from "../components/vehicle-selector"

/**
 * A `FormElement` represents one form widget on the page. Typically most `ChecklistItem`s will have
 * only a single form element, but they can have several (e.g. a single checklist item can contain
 * both a text box and a file upload component).
 */
interface FormElement {
  item: ChecklistItem
  id: string
  initialValue: unknown
  fieldName: string
  emptyValue: unknown
  rendered: React.ReactNode
  hideClearButton?: boolean
}

/** Whole page body (under the toolbars) component */
export const ChecklistView = ({ checklistUid }: { checklistUid: string }) => {
  const { data: permissions } = useGlobalFetcher("maintenancePermissions")
  const [checklist, setChecklist] = useState<Checklist>(null)
  const [error, setError] = useState<EmberApiError>(null)

  useGlobalFetcher("locations")

  useEffect(() => {
    setChecklist(null)
    setError(null)
    fetchChecklistByUid({
      checklistUid,
      onSuccess: setChecklist,
      onError: setError,
    })
  }, [checklistUid])

  const pageTitle =
    (checklist
      ? `${checklistTypeToLabel(checklist.type)} - ${
          checklist.vehicle.plateNumber
        }`
      : "Loading...") + " - Checklists"

  return (
    <>
      <Helmet title={pageTitle} />
      {error ? (
        <Alert type="critical" dataTest="checklist-loading-error">
          <Text>{error.message}</Text>
        </Alert>
      ) : checklist == null ? (
        <Loading type="boxLoader" text="Loading checklist..." />
      ) : (
        <Stack spacing="XXLarge">
          <Stack direction="column" spacing="none">
            <Heading type="title2" dataTest="checklist-type-header">
              {checklistTypeToLabel(checklist.type)}
            </Heading>
            <Vehicle checklist={checklist} setChecklist={setChecklist} />
            <ScheduledWork checklist={checklist} setChecklist={setChecklist} />
            <Deadline checklist={checklist} setChecklist={setChecklist} />
          </Stack>
          {permissions?.mayUseMaintenanceChat && (
            <ConversationView conversation={checklist.conversation} />
          )}
          <ChecklistForm checklist={checklist} setChecklist={setChecklist} />
        </Stack>
      )}
    </>
  )
}

const Vehicle = ({
  checklist,
  setChecklist,
}: {
  checklist: Checklist
  setChecklist: (checklist: Checklist) => void
}) => {
  const { data: permissions } = useGlobalFetcher("maintenancePermissions")
  const { saveValue, isSaving, savingError } = useChecklistField({
    checklist,
    setChecklist,
    field: "vehicleId",
  })
  return (
    <VehicleSelector
      current={checklist.vehicle}
      save={(vehicle: VehicleSummary) => saveValue(vehicle.id)}
      isSaving={isSaving}
      savingError={savingError}
      readOnly={
        !permissions?.mayModifyChecklistVehicle ||
        // once a checklist is completed, you can no longer modify the vehicle
        !!checklist.completedAt
      }
    />
  )
}

/** Main content, under the page header: the questions and the submit button */
const ChecklistForm = ({
  checklist,
  setChecklist,
}: {
  checklist: Checklist
  setChecklist: (checklist: Checklist) => void
}) => {
  const [isSubmitting, setSubmitting] = useState<boolean>(false)
  const [error, setError] = useState<EmberApiError>()

  const onSubmit = () => {
    setSubmitting(true)
    setError(null)
    markChecklistAsComplete({
      checklistUid: checklist.uid,
      onSuccess: (checklist: Checklist) => {
        setChecklist(checklist)
        setSubmitting(false)
      },
      onError: (error: EmberApiError) => {
        setError(error)
        setSubmitting(false)
      },
    })
  }

  if (!checklist.items?.length) {
    return (
      <Alert type="info">
        <Text>This checklist is empty</Text>
      </Alert>
    )
  }

  return (
    <Formik
      initialValues={Object.fromEntries(
        compileFormElements(checklist, {}, () => null).map((elem) => [
          elem.id,
          elem.initialValue ?? elem.emptyValue,
        ])
      )}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) => (
        <ChecklistFormBody
          checklist={checklist}
          formElements={compileFormElements(checklist, values, setFieldValue)}
          formValues={values}
          isSubmitting={isSubmitting}
          submissionError={error}
        />
      )}
    </Formik>
  )
}

/**
 * Helper for the above, broken out into a separate component so that we have a place to call
 *`useAutoSave`
 */
const ChecklistFormBody = ({
  checklist,
  formElements,
  formValues,
  isSubmitting,
  submissionError,
}: {
  checklist: Checklist
  formElements: FormElement[]
  formValues: Record<string, any>
  isSubmitting: boolean
  submissionError: EmberApiError
}) => {
  const { checklistItems, itemIsSaving, itemErrors } = useAutoSave({
    checklist,
    formElements,
    formValues,
  })
  return (
    <Form>
      <Stack direction="column" spacing="XLarge">
        <Stack direction="column" spacing="large">
          {groupItemsIntoSections(checklistItems).map((section: Section) => (
            <EmberCardGroup
              key={section.items.map((item) => item.uid).join()}
              sectionTitle={section.title}
            >
              <EmberCard>
                {section.items.map((item: ChecklistItem) => (
                  <ChecklistFormItem
                    key={item.uid}
                    checklist={checklist}
                    item={item}
                    isSaving={itemIsSaving[item.uid]}
                    errors={itemErrors[item.uid]}
                    formElements={formElements.filter(
                      (elem) => elem.item.uid == item.uid
                    )}
                  />
                ))}
              </EmberCard>
            </EmberCardGroup>
          ))}
        </Stack>
        {submissionError && (
          <Alert type="critical" dataTest="submission-error">
            <Text>{submissionError.message}</Text>
          </Alert>
        )}
        {checklist.completedAt ? (
          <Stack direction="row" justify="center" spacing="small">
            <CheckCircleIcon color="success" size="large" />
            <Text>
              Completed at{" "}
              {formatAsDateTime(checklist.completedAt, getDefaultTimezone())} by{" "}
              {getPersonName(checklist.completedBy)}
            </Text>
          </Stack>
        ) : (
          <Button
            fullWidth
            submit
            disabled={
              !checklistItems.every(
                (item) => item.template.optional || itemIsCompleted(item)
              ) || isSubmitting
            }
            loading={isSubmitting}
            dataTest="complete-checklist-button"
          >
            Complete Checklist
          </Button>
        )}
      </Stack>
    </Form>
  )
}

/** One question, rendered as a card */
const ChecklistFormItem = ({
  checklist,
  item,
  isSaving,
  errors,
  formElements,
}: {
  checklist: Checklist
  item: ChecklistItem
  isSaving: boolean
  errors: Array<EmberApiError | ValidationMessage>
  formElements: FormElement[]
}) => {
  const { setFieldValue } = useFormikContext()

  return (
    <EmberCardSection key={item.uid} dataTest={`form-item=${item.uid}`}>
      <Stack direction="row" spacing="XSmall" align="start" spaceAfter="none">
        <Stack inline shrink>
          <ProgressIndicator
            item={item}
            isSaving={isSaving}
            hasError={errors?.length > 0}
          />
        </Stack>
        <Stack direction="column" align="stretch" spacing="large" shrink>
          <Stack direction="column" spacing="small">
            <Stack direction="row" justify="between">
              <Heading type="title3">
                {item.template.question}
                {item.template.optional && (
                  <Text as="span" italic>
                    {" - Optional"}
                  </Text>
                )}
              </Heading>
              {itemIsCompleted(item) &&
                !checklist.completedAt &&
                !formElements.every((elem) => elem.hideClearButton) && (
                  <TextLink
                    type="secondary"
                    onClick={() => {
                      for (const elem of formElements) {
                        if (!elem.hideClearButton) {
                          setFieldValue(elem.id, elem.emptyValue)
                        }
                      }
                    }}
                  >
                    Clear
                  </TextLink>
                )}
            </Stack>
            {item.template.instructions && (
              <Text type="secondary">{item.template.instructions}</Text>
            )}
          </Stack>
          <Stack direction="column" align="stretch">
            {formElements.map((elem) => (
              <React.Fragment key={elem.id}>{elem.rendered}</React.Fragment>
            ))}
          </Stack>
          {errors?.map((error: EmberApiError | ValidationMessage) => (
            <Alert
              type={
                "level" in error && error.level == "warning"
                  ? "info"
                  : "critical"
              }
              key={error.message}
            >
              <Text>{error.message}</Text>
            </Alert>
          ))}
        </Stack>
      </Stack>
    </EmberCardSection>
  )
}

/**
 * Creates `FormElement` objects based on the given `ChecklistItem`s. Each item is a question, and
 * corresponds to one or more elements (one element is one form input widget, some questions contain
 * more than one).
 *
 * The point of using `FormElement` is to group together ID string generation, validation, and
 * rendering.
 */
const compileFormElements = (
  checklist: Checklist,
  values: Record<string, unknown>,
  setFieldValue: (string, unknown) => void
): FormElement[] => {
  const formElements: FormElement[] = []
  for (const item of checklist.items) {
    // radio questions
    if (item.template.allowedOptions?.length > 1) {
      formElements.push({
        item,
        id: `${item.uid}-radio`,
        fieldName: "chosenOption",
        initialValue: item.chosenOption,
        emptyValue: "",
        rendered: (
          <EmberRadioGroup
            name={`${item.uid}-radio`}
            options={item.template.allowedOptions.map((option: string) => ({
              value: option,
              label: option,
            }))}
            disabled={!!checklist.completedAt}
            showAllOptionsWhenDisabled
            dataTest={`${item.uid}-radio`}
          />
        ),
      })
    }

    // checkbox questions
    if (item.template.allowedOptions?.length == 1) {
      formElements.push({
        item,
        id: `${item.uid}-checkbox`,
        fieldName: "chosenOption",
        initialValue: item.chosenOption,
        emptyValue: "",
        rendered: (
          <Checkbox
            name={`${item.uid}-checkbox`}
            value={item.template.allowedOptions[0]}
            label={item.template.allowedOptions[0]}
            checked={!!values[`${item.uid}-checkbox`]}
            onChange={(event) =>
              setFieldValue(
                `${item.uid}-checkbox`,
                event.currentTarget.checked
                  ? item.template.allowedOptions[0]
                  : null
              )
            }
            disabled={!!checklist.completedAt}
            dataTest={`${item.uid}-checkbox`}
          />
        ),
      })
    }

    // free text questions
    if (item.template.freeTextAllowed) {
      formElements.push({
        item,
        id: `${item.uid}-text`,
        fieldName: "freeText",
        initialValue: item.freeText,
        emptyValue: "",
        rendered: (
          <EmberTextArea
            name={`${item.uid}-text`}
            disabled={!!checklist.completedAt}
            dataTest={`${item.uid}-text`}
          />
        ),
      })
    }

    // number input questions
    if (item.template.numberAllowed) {
      formElements.push({
        item,
        id: `${item.uid}-number`,
        fieldName: "number",
        initialValue: item.number,
        emptyValue: "",
        rendered: (
          <Stack inline shrink>
            <EmberNumberInput
              name={`${item.uid}-number`}
              disabled={!!checklist.completedAt}
              dataTest={`${item.uid}-number`}
            />
          </Stack>
        ),
      })
    }

    // date input questions
    if (item.template.dateAllowed) {
      formElements.push({
        item,
        id: `${item.uid}-date`,
        fieldName: "date",
        initialValue: item.date,
        emptyValue: "",
        rendered: (
          <EmberDateInput
            name={`${item.uid}-date`}
            disableTextEditing /* <-- this means clicking in the text box opens the calendar */
            disabled={!!checklist.completedAt}
            serialisationFormat="yyyy-MM-dd"
            shrink
            dataTest={`${item.uid}-date`}
          />
        ),
      })
    }

    // datetime input questions
    if (item.template.datetimeAllowed) {
      formElements.push({
        item,
        id: `${item.uid}-datetime`,
        fieldName: "datetime",
        initialValue: item.datetime,
        emptyValue: "",
        rendered: (
          <EmberDateInput
            name={`${item.uid}-datetime`}
            showTime
            disableTextEditing /* <-- this means clicking in the text box opens the calendar */
            disabled={!!checklist.completedAt}
            shrink
            dataTest={`${item.uid}-datetime`}
          />
        ),
      })
    }

    // file uploads
    if (item.template.fileUploadsAllowed) {
      formElements.push({
        item,
        id: `${item.uid}-files`,
        fieldName: "fileUploads",
        initialValue: item.fileUploads.map((file) => file.uid),
        emptyValue: [],
        rendered: (
          <ChecklistFormFileItem
            name={`${item.uid}-files`}
            initialUploadedFiles={item.fileUploads ?? []}
            disabled={!!checklist.completedAt}
            dataTest={`${item.uid}-files`}
          />
        ),
        // 2024-02-21 - the "clear" button doesn't work for file uploads, because although it
        // updates the list of files on the ChecklistItem object itself, the list of files held
        // internally by `useAttachments` isn't updated. Maybe the proper solution is to have a
        // version of `useAttachments` that doesn't own state, and can take the list of files from
        // its props. Anyway, no time to do this now, so we just hide the "clear" button on file
        // uploads. Each file has a "delete" button anyway.
        hideClearButton: true,
      })
    }
  }

  return formElements
}

/**
 * File upload widget
 */
const ChecklistFormFileItem = ({
  name,
  initialUploadedFiles,
  disabled = false,
  dataTest,
}: {
  name: string
  initialUploadedFiles: UploadedFile[]
  disabled?: boolean
  dataTest?: string
}) => {
  const { AttachFilesButton, FileGallery, uploadedFiles, error } =
    useAttachments({
      context: { rootUrl: "/v1/maintenance/attachments/" },
      initialUploadedFiles,
      attachFilesButton: (
        <Button
          type="secondary"
          iconLeft={<AttachmentIcon />}
          asComponent="div"
        >
          Upload
        </Button>
      ),
      editable: !disabled,
      multiple: true,
      autoFetchFileUrls: true,
    })

  const [, , { setValue }] = useField(name)
  useEffect(() => {
    setValue(uploadedFiles.map((file) => file.uid))
  }, [uploadedFiles])

  return (
    <Stack dataTest={dataTest}>
      <FileGallery />
      {!disabled && (
        <Stack inline shrink>
          <AttachFilesButton />
        </Stack>
      )}
      {error && (
        <Alert type="critical">
          <Text>{error.message}</Text>
        </Alert>
      )}
    </Stack>
  )
}

/** The little coloured circle that indicates whether a question has been completed */
const ProgressIndicator = ({
  item,
  isSaving,
  hasError,
}: {
  item: ChecklistItem
  isSaving: boolean
  hasError: boolean
}) => {
  const theme = useContext(ThemeContext)

  let tooltip = ""
  if (item.updatedAt) {
    tooltip = `Filled out at ${formatAsDateTime(
      item.updatedAt,
      getDefaultTimezone()
    )}`
    if (item.updatedBy) {
      tooltip += ` by ${getPersonName(item.updatedBy)}`
    }
  } else {
    tooltip = "Not filled out"
  }

  return (
    <Box minWidth="20px">
      {isSaving ? (
        <Loading
          type="inlineLoader"
          customSize={parseInt(theme.orbit.spaceMedium)}
        />
      ) : (
        <Tooltip content={tooltip}>
          {itemIsCompleted(item) ? (
            <CheckCircleIcon color="success" />
          ) : (
            <CircleIcon
              customColor={
                hasError
                  ? theme.orbit.paletteRedNormal
                  : theme.orbit.paletteInkLight
              }
            />
          )}
        </Tooltip>
      )}
    </Box>
  )
}

/**
 * Component that displays the scheduled work for the checklist, and when clicked opens the modal to
 * edit the schedule
 */
const ScheduledWork = ({
  checklist,
  setChecklist,
}: {
  checklist: Checklist
  setChecklist: (checklist: Checklist) => void
}) => {
  const [isModalVisible, setModalVisible] = useState<boolean>(false)
  const [error, setError] = useState<EmberApiError>()
  const { data: permissions } = useGlobalFetcher("maintenancePermissions")

  const readOnly =
    !permissions?.mayModifyChecklistActivities ||
    // once a checklist is completed, you can no longer change its scheduled work
    !!checklist.completedAt
  const text =
    describeScheduledWork(checklist.activities) ||
    (readOnly ? "No Scheduled Work" : "Schedule Work")

  return (
    <Stack direction="row">
      <ShadowOnHover verticalShift="-3px" readOnly={readOnly}>
        <IconAndText
          icon={<CalendarIcon />}
          text={
            readOnly ? (
              text
            ) : (
              <TextLink
                type="secondary"
                noUnderline
                onClick={() => setModalVisible(true)}
              >
                {text}
              </TextLink>
            )
          }
        />
      </ShadowOnHover>

      {error && (
        <Tooltip content={error.message}>
          <AlertIcon />
        </Tooltip>
      )}

      {isModalVisible && (
        <ScheduledWorkModal
          vehicle={checklist.vehicle}
          existingActivities={checklist.activities}
          onActivityIdsChanged={(activityIds: number[]) => {
            modifyChecklist({
              checklistUid: checklist.uid,
              payload: { activityIds },
              onSuccess: (checklist: Checklist) => {
                setModalVisible(false)
                setChecklist(checklist)
              },
              onError: setError,
            })
          }}
          onActivityObjectsChanged={(activities: Activity[]) => {
            // We could just re-fetch the checklist, but since we have the fresh data here we can
            // just update the data directly
            setModalVisible(false)
            setChecklist({ ...checklist, activities })
          }}
          onClose={() => setModalVisible(false)}
        />
      )}
    </Stack>
  )
}

/**
 * Displays the deadline, and allows certain people to change it, too
 */
const Deadline = ({
  checklist,
  setChecklist,
}: {
  checklist: Checklist
  setChecklist: (checklist: Checklist) => void
}) => {
  const { data: permissions } = useGlobalFetcher("maintenancePermissions")
  const [showCalendar, setShowCalendar] = useState<boolean>(false)
  const { saveValue, isSaving, savingError } = useChecklistField({
    checklist,
    setChecklist,
    field: "deadline",
  })

  const readOnly =
    !permissions?.mayModifyChecklistDeadline || !!checklist.completedAt

  const timezone = getDefaultTimezone()
  const text = getDeadlineText(checklist.deadline)
  return (
    <IconAndText
      icon={
        <Invisible>
          <PlaceholderIcon />
        </Invisible>
      }
      text={
        <Box padding={{ top: "XXXSmall", bottom: "XXSmall" }}>
          <WithInlineSpinnerAndAlerts
            isSaving={isSaving}
            savingError={savingError}
          >
            <ShadowOnHover readOnly={readOnly}>
              {readOnly ? (
                text
              ) : (
                <TextLink
                  noUnderline
                  type="secondary"
                  onClick={() => setShowCalendar(true)}
                >
                  {text}
                </TextLink>
              )}
            </ShadowOnHover>
          </WithInlineSpinnerAndAlerts>
          <Popover
            allowOverflow={false}
            opened={showCalendar}
            onClose={() => setShowCalendar(false)}
            renderInPortal={false}
            lockScrolling={false}
            noPadding
            placement="bottom-start"
            dataTest="deadline-calendar"
            content={
              <EmberInlineDatePicker
                date={utcToZonedTime(
                  checklist.deadline || new Date().toISOString(),
                  timezone
                )}
                setDate={(value: Date) => {
                  saveValue(zonedTimeToUtc(startOfDay(value), timezone))
                }}
                closeOnSelect={() => setShowCalendar(false)}
              />
            }
          >
            <div style={{ height: "0px" }} />
          </Popover>
        </Box>
      }
    />
  )
}

const Invisible = styled.span`
  visibility: hidden;
`

/** Returns the deadline as an English sentence */
const getDeadlineText = (deadline: string) => {
  let deadlineStr = deadline
    ? formatAsAbbreviatedDate(deadline, getDefaultTimezone())
    : "not set"
  if (deadlineStr == formatAsDate(new Date(), getDefaultTimezone())) {
    deadlineStr = "today"
  } else if (
    deadlineStr == formatAsDate(addDays(new Date(), 1), getDefaultTimezone())
  ) {
    deadlineStr = "tomorrow"
  }
  return `Deadline ${deadlineStr}`
}

/**
 * Checks whether a question has been answered. The checklist can only be marked as complete once
 * all items are completed.
 */
const itemIsCompleted = (item: ChecklistItem): boolean =>
  !!(
    (item.template.allowedOptions && item.chosenOption) ||
    (item.template.freeTextAllowed && item.freeText) ||
    (item.template.numberAllowed && item.number) ||
    (item.template.dateAllowed && item.date) ||
    (item.template.datetimeAllowed && item.datetime) ||
    (item.template.fileUploadsAllowed && item.fileUploads?.length)
  )

/**
 * Sections are used to group questions visually under a title
 */
interface Section {
  title: string
  items: ChecklistItem[]
}

const groupItemsIntoSections = (items: ChecklistItem[]) => {
  const sections = []
  for (const item of sortBy(items, (item) => item.template.sequenceNumber)) {
    if (
      sections.length == 0 ||
      item.template.sectionName != sections[sections.length - 1].title
    ) {
      sections.push({ title: item.template.sectionName, items: [] })
    }
    sections[sections.length - 1].items.push(item)
  }
  return sections
}

/**
 * `useAutoSave` monitors for any changes to the values input by the user, and fires off requests to
 * auto-save whenever a change is made.
 */
const useAutoSave = ({
  checklist,
  formElements,
  formValues,
}: {
  checklist: Checklist
  formElements: FormElement[]
  formValues: Record<string, any>
}): {
  itemIsSaving: Record<string, boolean>
  itemErrors: Record<string, Array<EmberApiError | ValidationMessage>>
  checklistItems: ChecklistItem[]
} => {
  const [itemIsSaving, setItemIsSaving] = useState<Record<string, boolean>>({})
  const [itemErrors, setItemErrors] = useState<
    Record<string, Array<EmberApiError | ValidationMessage>>
  >({})
  const [checklistItems, setChecklistItems] = useState<ChecklistItem[]>(
    checklist.items
  )

  // returns { "item-uid": { "form-element-id": "value" }}, using current form values
  const currentValues = Object.fromEntries(
    checklist.items.map((item) => [
      item.uid,
      JSON.stringify(
        Object.fromEntries(
          formElements
            .filter((elem) => elem.item.uid == item.uid)
            .map((elem) => [elem.id, formValues[elem.id]])
        )
      ),
    ])
  )
  const [submittedValues, setSubmittedValues] =
    useState<Record<string, unknown>>(currentValues)

  // This is to debounce requests to the backend, just to avoid overloading it or pointlessly saving
  // every single character while the user is typing in a text box
  const COOL_OFF_MS = 2000
  const [isCoolingOff, setCoolingOff] = useState<boolean>(false)
  const startCoolingOff = () => {
    setCoolingOff(true)
    setTimeout(() => setCoolingOff(false), COOL_OFF_MS)
  }

  const { startSpinning, stopSpinningIfAppropriate } = useLoadingSpinners({
    itemIsSaving,
    setItemIsSaving,
  })

  useEffect(() => {
    for (const item of checklistItems) {
      if (
        currentValues[item.uid] != submittedValues[item.uid] &&
        !itemIsSaving[item.uid] &&
        !isCoolingOff
      ) {
        startSpinning(item.uid)
        setItemErrors({ ...itemErrors, [item.uid]: null })
        setSubmittedValues({
          ...submittedValues,
          [item.uid]: currentValues[item.uid],
        })
        updateChecklistItem({
          checklistUid: checklist.uid,
          itemUid: item.uid,
          payload: Object.fromEntries(
            formElements
              .filter((elem) => elem.item.uid == item.uid)
              .map((elem) => [
                elem.fieldName,
                formValues[elem.id] === elem.emptyValue
                  ? null
                  : formValues[elem.id],
              ])
          ),
          onSuccess: (
            checklistItem: ChecklistItem,
            validationMessages: ValidationMessage[]
          ) => {
            setChecklistItems(
              checklistItems.map((item) =>
                item.uid == checklistItem.uid ? checklistItem : item
              )
            )
            setItemErrors({ ...itemErrors, [item.uid]: validationMessages })
            startCoolingOff()
            stopSpinningIfAppropriate(item.uid, true)
          },
          onError: (error: EmberApiError) => {
            setItemErrors({ ...itemErrors, [item.uid]: [error] })
            startCoolingOff()
            stopSpinningIfAppropriate(item.uid, true)
          },
        })
      }
    }
  }, [JSON.stringify([itemIsSaving, formValues, isCoolingOff])])

  return {
    itemIsSaving,
    itemErrors,
    checklistItems,
  }
}

/**
 * This is to ensure a minimum duration for the "loading" spinner, so that it doesn't just flash so
 * quickly that the user can't see it.
 */
const useLoadingSpinners = ({
  itemIsSaving,
  setItemIsSaving,
}: {
  itemIsSaving: Record<string, boolean>
  setItemIsSaving: (itemIsSaving: Record<string, boolean>) => void
}): {
  startSpinning: (itemUid: string) => void
  stopSpinningIfAppropriate: (itemUid: string, markAsComplete: boolean) => void
} => {
  const MIN_SPINNER_DURATION_MS = 1000
  interface RunningRequest {
    startTime: number
    isComplete: boolean
  }

  // Using `useRef` rather than `useState` means we can avoid the stale closure problem
  const runningRequestByItem = useRef<Record<string, RunningRequest>>({})

  const startSpinning = (itemUid: string) => {
    runningRequestByItem.current[itemUid] = {
      startTime: new Date().getTime(),
      isComplete: false,
    }
    setItemIsSaving({ ...itemIsSaving, [itemUid]: true })
    setTimeout(
      () => stopSpinningIfAppropriate(itemUid),
      MIN_SPINNER_DURATION_MS
    )
  }

  const stopSpinningIfAppropriate = (
    itemUid: string,
    markAsComplete = false
  ) => {
    const request = runningRequestByItem.current[itemUid]
    if (markAsComplete) {
      request.isComplete = true
    }
    if (
      // only stop spinning if the request has completed _and_ we've waited the minimum delay
      request?.isComplete &&
      new Date().getTime() >= request.startTime + MIN_SPINNER_DURATION_MS
    ) {
      setItemIsSaving({ ...itemIsSaving, [itemUid]: false })
    }
  }

  return { startSpinning, stopSpinningIfAppropriate }
}

const useChecklistField = <K extends keyof ChecklistModificationPayload, V>({
  field,
  checklist,
  setChecklist,
}: {
  field: K
  checklist: Checklist
  setChecklist: (checklist: Checklist) => void
}): {
  saveValue: (newValue: V) => Unsubscribable
  isSaving: boolean
  savingError: EmberApiError
} => {
  const [isSaving, setSaving] = useState<boolean>(false)
  const [savingError, setSavingError] = useState<EmberApiError>(null)

  const saveValue = (newValue: V): Unsubscribable => {
    setSaving(true)
    return modifyChecklist({
      checklistUid: checklist.uid,
      payload: { [field]: newValue },
      onSuccess: (checklist: Checklist) => {
        setSaving(false)
        setSavingError(null)
        setChecklist(checklist)
      },
      onError: (error: EmberApiError) => {
        setSaving(false)
        setSavingError(error)
      },
    })
  }

  return {
    saveValue,
    isSaving,
    savingError,
  }
}

import React from "react"

import { ChecklistView } from "components/maintenance/checklists/details"
import { MaintenancePage } from "components/maintenance/layout"

import { MaintenancePermissions } from "types/issue"

const Page = ({ params: { checklistUid } }) => (
  <MaintenancePage
    checklistUid={checklistUid}
    title="Checklist"
    accessControl={(permissions: MaintenancePermissions) =>
      permissions.mayViewChecklistDetails
    }
  >
    <ChecklistView checklistUid={checklistUid} />
  </MaintenancePage>
)

export default Page

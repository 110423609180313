// @ts-strict-ignore
import React from "react"

import styled from "styled-components"

export const ShadowOnHover = ({
  children,
  verticalShift,
  readOnly = false,
}: {
  children: React.ReactNode
  verticalShift?: string
  readOnly?: boolean
}) => {
  return readOnly ? (
    <div>{children}</div>
  ) : (
    <div>
      <ShadowOnHoverWrapper
        onClick={(event) => {
          // This whole onclick handler is a kludge that fixes an issue where clicking the outer
          // edge of the hover shadow wouldn't actually click the icon. We avoid the issue by
          // descending into children and "forwarding" the click to the first child that we can find
          // that's a content node.
          //
          // It works for now, but if it ever breaks a better solution might be to move
          // `ShadowOnHover` inside the various component that are currently placed as its children.
          // So for example instead of wrapping `EmberDatePicker` in a `ShadowOnHover`,
          // `EmberDatePicker` would have a boolean `shadowOnHover` option that would internally
          // wrap the contents in a ShadowOnHover, such that the component with the "onclick"
          // handler is a parent of `ShadowOnHover`, and this whole issue is sidestepped.
          //
          let node = event.target as any
          if (!`${node.className}`.includes("shadow-on-hover")) {
            // This avoids a bug where clicks become double-clicks, because our call to `.click()`
            // below triggers this handler a second time.
            return
          }
          if (node.tagName == "svg") {
            node = node.parentNode
          }
          for (;;) {
            let next = null
            for (const child of node.children) {
              if (child.tagName && child.tagName != "svg") {
                next = child
                break
              }
            }
            if (next != null) {
              node = next
            } else {
              break
            }
          }
          if (node && typeof node.click == "function") {
            node.click()
          }
        }}
        verticalShift={verticalShift}
      >
        {children}
      </ShadowOnHoverWrapper>
    </div>
  )
}

const ShadowOnHoverWrapper = styled.div<{ verticalShift: string }>`
  cursor: pointer;
  display: inline-block;

  padding-top: calc(6px - ${(props) => props.verticalShift ?? "0px"});
  padding-right: 10px;
  padding-bottom: calc(5px + ${(props) => props.verticalShift ?? "0px"});
  padding-left: 10px;
  margin-top: calc(-6px + ${(props) => props.verticalShift ?? "0px"});
  margin-right: -10px;
  margin-bottom: calc(-5px - ${(props) => props.verticalShift ?? "0px"});
  margin-left: -10px;

  border-radius: 4px;
  width: fit-content;
  :hover {
    background: ${(props) => props.theme.orbit.paletteCloudLightHover};
  }
`
